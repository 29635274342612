import { Route, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
const Home = loadable(() => import('../pages/Home'));
const About = loadable(() => import('../pages/About'));
const FAQs = loadable(() => import('../pages/Faq'));
const Layout = loadable(() => import('../layout/Layout'));
const Blogs = loadable(() => import('../pages/Blogs'));
const BlogDetail = loadable(() => import('../pages/BlogDetail'));
const DubaiMarina = loadable(() => import('../pages/Locations/DubaiMarina'));
const DubaiDowntown = loadable(() => import('../pages/Locations/DubaiDowntown'));
const Contact = loadable(() => import('../pages/Contact'));
const Franchise = loadable(() => import('../pages/Franchise'));
const DowntownTour = loadable(() => import('../pages/VirtualTour/Downtown'));
const MarinaTour = loadable(() => import('../pages/VirtualTour/Marina'));
const ServiceDetail = loadable(() => import('../pages/ServiceDetail'));
const PageThankYou = loadable(() => import('../pages/ThankYou'));
const CSR = loadable(() => import('../pages/Corporate'));
const PageNotFound = loadable(() => import('../pages/404'));
const RussianHomePage = loadable(() => import('../pages/Russian/Home'));

function Routes() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/ru" component={RussianHomePage}/>
        
        <Route exact path="/who-we-are/" component={About} />
        <Route exact path="/frequently-asked-questions/" component={FAQs} />
        <Route exact path="/blog/" component={Blogs} />
        <Redirect
          from="/blog/leasing-an-office-in-a-business-centre-benefits-of-a-prestigious-location/"
          to="/blog/benefits-of-a-prestigious-office-why-location-is-important-for-business/"
        />
        <Redirect
          from="/blog/5-reasons-why-outsourcing-pro-services-will-help-your-business-expand/"
          to="/blog/pro-services-in-dubai-company-formation-visa-licenses/"
        />
        <Redirect
          from="/blog/types-of-business-networking-events-that-support-company-expansion/"
          to="/blog/types-of-business-networking-events/"
        />
        <Route exact path="/blog/:id/" component={BlogDetail} />
        <Route exact path="/dubai-marina-plaza/" component={DubaiMarina} />
        <Redirect from="/dubai-marina/" to="/dubai-marina-plaza/"/>
        <Route exact path="/boulevard-plaza/" component={DubaiDowntown} />      
        <Redirect from="/downtown-dubai/" to="/boulevard-plaza/"/>
        <Redirect from="/boulenvard-plaza/" to="/boulevard-plaza/"/>
        <Route exact path="/contact/" component={Contact} />
        <Route exact path="/contact-us/" component={Contact} />
        <Route exact path="/partner-with-us/" component={Franchise} />
        <Route
          exact
          path="/downtown-dubai-virtual-tour/"
          component={DowntownTour}
        />
        <Route
          exact
          path="/dubai-marina-virtual-tour/"
          component={MarinaTour}
        />
        <Route exact path="/thank-you/" component={PageThankYou} />
        <Redirect from="/meeting-room/"  to="/meeting-room-dubai/"/>
        <Redirect path="/serviced-offices/"  to="/serviced-offices-dubai/"/>
        <Redirect path="/co-working-space/" to="/co-working-space-dubai/"/>
        <Redirect path="/event-spaces/" to="/corporate-event-spaces-dubai/"/>
        <Route exact path="/:id/" component={ServiceDetail} />
        <Route exact path="/blog/*/" component={ServiceDetail} />
        <Redirect from="/blog/ar/:id/" to="/blog/" />
        <Redirect from="/services/:id" to="/:id/"/>
        <Redirect
          from="/virtual_tours/marina/"
          to="/dubai-marina-virtual-tour/"
        />



        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  );
}

export default Routes;